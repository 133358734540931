import Header from "../Components/Header";
import Review from "../Components/Review";
import "./reviews.css";
import reviews from "../data/reviews.json";

export default function Reviews() {
    return (
        <div className="reviews" style={{ position: "relative" }}>
            <Header name={"Reviews"} />
            <div className="reviews-header ovoky-text-style-title-H1">Loved by millions, big and small</div>
            <Review reviews={reviews} numberOfReviewsToShow={4} />
        </div>
    )
}
