import { Children } from "react";
import "./articles-list.css";

export default function ArticlesList({ children , title , titleColor, fontTitle}) {
    return (
        <div className="articles-list">
            <div className='articles-head'>
                <h1 className="ovoky-text-style-H0-title-hero" style={{color : titleColor, fontSize: fontTitle}}>{title}</h1>
                <div className="articlesList-line"></div>
            </div>
            {
                Children.map(children, child => (
                    <>{child}</>
                ))
            }
        </div>
    )
}
