import React from 'react'
import Carousel from '../Components/Carousel'
import './hero-section.css'

export default function HeroSection({ onclick }) {
    const images = [
        {
            src: "/images/slide1.jpg",
            alt: "image 1",
            width: "100%"

        },
        {
            src: "/images/slide2.png",
            alt: "image 2"

        },
        {
            src: "/images/slide3.jpg",
            alt: "image 3",
            width: "100%",
            transform: "rotateY(180deg)"
        }
    ]
    const options = [
        {
            h1: "Unify",
            h2: "your communication.",
            p: "Standardize your communication to ensure effective consistency in all your messages and strategically achieve your objectives."
        },
        {
            h1: "Diversify",
            h2: "your communication.",
            p: "Diversify your communication to make it more engaging and captivating for your audience and maximize its impact."
        },
        {
            h1: "Simplify",
            h2: "your communication.",
            p: "Simplify your communication to make it clearer and more accessible to everyone."
        },
    ]
    return (
        <>
            <div className='hero-section' onClick={onclick}>
                <div className='bg-repeat'></div>
                <div className='bg-linear'></div>
                <Carousel images={images} options={options} />
            </div>
        </>
    )
}
