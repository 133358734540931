import "./card-blog.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
export default function CardBlog({ option }) {
    return (
        <div className="card-blog">
            <div className='card-container'>
                <div className="overlay"></div>
                <div className="author-info-container">
                    <div>
                        <p><img src={option.authorImg1} alt="" />{option.authorName1}</p>
                    </div>
                    <p>{option.date1}</p>
                </div>
                <div className="article-info-container">
                    <h3>{option.title1}</h3>
                </div>
                <div className="bgImage" style={{ background: "url(/images/" + option.bgImg1 + ") center center/cover no-repeat" }}></div>
                <div className={option.direction === "vertical" ? "reaction vertical" : "reaction horizontal"} >
                    <div><RemoveRedEyeOutlinedIcon />{option.viewNum}</div>
                    <div><FavoriteBorderOutlinedIcon />{option.likeNum}</div>
                    <div><ReplyOutlinedIcon />{option.shareNum}</div>
                </div>
            </div>
        </div>
    )
}
