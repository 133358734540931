import React from 'react'
import "./author-card.css";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
export default function AuthorCard({ options }) {
    return (
        <>
            {
                options.map((option, index) => (
                    <div className='author-card' key={index}>
                        <img src={option.authorImg} alt="" />
                        <div>
                            <h4>{option.authorName}</h4>
                            <div>
                                <p><ArticleOutlinedIcon />{option.numberArticle} </p>
                                <p><PersonOutlineOutlinedIcon /> {option.numberFollower} follower</p>
                            </div>
                        </div>
                    </div>
                ))
            }

        </>
    )
}
