import { Children } from "react";
import "./accordion.css";


export default function Accordion({ handleClick, open, title, theme, children, openStyle }) {
    const classList = ["accordion", theme, openStyle]
    return (
        <>
            <div className={classList.join(" ")} onClick={handleClick}><h3 className="ovoky-text-style-title-H3">{title}</h3>
                <div className={open === true ? "bars-icon active" : "bars-icon"}>
                    <span className="bar-icon"></span>
                    <span className="bar-icon"></span>
                </div>
            </div>
            <>
                {
                    open &&
                    <>
                        {
                            Children.map(children, child => <>{child}</>
                            )
                        }
                    </>
                }

            </>
        </>
    )
}
