import "./plan.css";
import Button from './Button'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Numbers from "./Numbers";
import Calls from "./Calls";
import Messaging from "./Messaging";

export default function Plans({ handleOpen, messagingOptions, callsOptions, numbersOptions, planName, businessesType, planDescp, theme, btnBgColor, btnColor }) {
    const classList = ["plan plan-vertical", theme]
    return (
        <div className={classList.join(" ")}>
            <div className='plan-item' >
                <p className="plan-type">{planName}</p>
                <h2 className="ovoky-text-style-title-H2">{businessesType}</h2>
                <p className="ovoky-text-style-p2-small descp">{planDescp}</p>
                <div className="plan-line"></div>
                <div className="items">
                    {
                        numbersOptions.length !== 0 &&
                        <Numbers options={numbersOptions} />
                    }
                    {
                        callsOptions.length !== 0 &&
                        <Calls options={callsOptions} />
                    }
                    {
                        messagingOptions.length !== 0 &&
                        <Messaging options={messagingOptions} />
                    }
                </div>
            </div>
            <div className="plan-button">
                <Button onClick={handleOpen} btnBgColor={btnBgColor} btnColor={btnColor} name={"Start for free"} icon={<ArrowRightAltIcon />} theme={"ovoky-comp-button-blue-big-button"} />
            </div>
        </div>
    )
}
