import React, { useState } from 'react';
import { startOfMonth, endOfMonth, addMonths, eachDayOfInterval, format } from 'date-fns';
import "./calendar.css";
import DropDown from './DropDown';

const Calendar = () => {
  const years = [
    {
      title: 2024
    },
    {
      title: 2023
    },
    {
      title: 2022
    },
    {
      title: 2021
    },
    {
      title: 2020
    }
  ]
  const startDate = new Date();
  const firstMonthStart = startOfMonth(startDate);
  const secondMonthStart = startOfMonth(addMonths(startDate, 1));

  const firstMonthDays = eachDayOfInterval({ start: firstMonthStart, end: endOfMonth(firstMonthStart) });
  const secondMonthDays = eachDayOfInterval({ start: secondMonthStart, end: endOfMonth(secondMonthStart) });

  const weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

  const [selectDayOfFirstMonth, setSelectDayofFirstMonth] = useState(firstMonthDays.map(d => false));
  const [selectDayOfSecondMonth, setSelectDayOfSecondMonth] = useState(secondMonthDays.map(d => false));
  const onSelectDay = (index, setter) => {
    setter(prev => {
      const newDay = [...prev];
      newDay[index] = !newDay[index];
      return newDay;
    })
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggleOptions = () => {
    setIsOpen(prev => !prev)
  }
  const [selectedYear, setSelectedYear] = useState(years[0].title);
  const handleOptionSelect = (optionTitle) => {
    setSelectedYear(optionTitle);
    setIsOpen(false)
  };
  return (
    <div className="calendar">

      <DropDown 
      filteredOptions={years.map(y => ({title : y.title , value : y.title}))} 
      theme={"drop-down-calendar"} 
      themeOptions={"options-drop-down-calendar"}
      isOpen={isOpen}
      toggleOptions={toggleOptions}
      selectedOption={selectedYear}
      handleOptionSelect={handleOptionSelect}

       />
      <div className='months-container'>
        <div className="month">
          <h2>{format(firstMonthStart, 'MMMM')}</h2>
          <div className="weekdays">
            {weekdays.map(day => (
              <div key={day} className="weekday">
                {day}
              </div>
            ))}
          </div>
          <div className="days">
            {firstMonthDays.map((day, index) => (
              <div key={day} className={selectDayOfFirstMonth[index] === true ? "day active" : "day"} onClick={() => onSelectDay(index, setSelectDayofFirstMonth)}>
                {format(day, 'd')}
              </div>
            ))}
          </div>
        </div>
        <div className="month">
          <h2>{format(secondMonthStart, 'MMMM')}</h2>
          <div className="weekdays">
            {weekdays.map(day => (
              <div key={day} className="weekday">
                {day}
              </div>
            ))}
          </div>
          <div className="days">
            {secondMonthDays.map((day, index) => (
              <div key={day} className={selectDayOfSecondMonth[index] === true ? "day active" : "day"} onClick={() => onSelectDay(index, setSelectDayOfSecondMonth)}>
                {format(day, 'd')}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='confirm-btn'>
        <button>Confirm</button>
      </div>
    </div>
  );
};

export default Calendar;
