import MainBlog from "../Components/MainBlog";
import Subscribe from "./Subscribe";
import "./blog.css";
import frequentlyAQ from "../data/frequentlyAQ.json";
import FrequentlyAQ from "./FrequentlyAQ";
import News from "../Components/News";
import { useState } from "react";


export default function Blog({ handleClick }) {
   
    const [show, setShow] = useState(false)
    return (
        <>
        {
            show === true &&
            <div className="blog" onClick={handleClick}>
                <MainBlog />
            </div>
        }
            <div>
                <News setShowSearchBar={setShow} show={show} />
                <Subscribe />
                <FrequentlyAQ fastAQ={frequentlyAQ} />
            </div>
        </>
    )
}
