import "./card.css"
import Button from './Button'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export default function Card({ options , currentIndex }) {
    
    return (
        <>

            {
                options.map((option, index) => (
                    <div className= {index === currentIndex ? "main-card show" : "main-card hide"} key={index} value={index}>
                        <div className={'card '+ option.theme}>
                            <div className="card-info">
                                <p className="ovoky-text-style-lables name">{option.name}</p>
                                <h2 className="ovoky-text-style-title-H2">{option.title}</h2>
                                <p className="ovoky-text-style-p2-small">{option.parag} </p>
                                <ul>
                                    {option.list.map((l, i) => (
                                        <li className="ovoky-text-style-p1-body" key={i}><span>{l.li}</span></li>
                                    ))}
                                </ul>
                            </div>
                            <div className="card-button">
                                <Button href={"/#form"} btnColor={option.btnColor} btnBgColor={option.btnBgColor} name={"Talk to an expert"} icon={<ArrowRightAltIcon />} theme={"ovoky-comp-button-blue-bg"} />
                            </div>
                        </div>
                        <div className="card-image">
                            <img src={option.srcImage} alt={`card`} />
                        </div>
                    </div>
                ))
            }
        </>
    )
}
