import "./pricing.css";
import Header from '../Components/Header'
import DropDown from "../Components/DropDown";
import Plans from "../Components/Plans";
// import featues from "../data/features.json";
// import Features from "../Components/Features";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useCallback, useEffect, useRef, useState } from "react";
import config from "../config";

export default function Pricing({ handleOpen }) {

    const [countries, setCountries] = useState([]);
    const [isOpenCountryDropDown, setIsOpenCoutryDropDown] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState();
    const [inputValue, setInputValue] = useState('');
    const [pricingNumbers, setPricingNumbers] = useState([]);
    const [pricingCalls, setPricingCalls] = useState([]);
    const [pricingMessaging, setPricingMessaging] = useState([]);

    const dropdownRef = useRef(null);
    const getCountries = useCallback(() => {

        // const baseURL = window.location.host;
        const CountriesTarget = new URL(config.API_BASE + "/prices/countries");
        fetch(CountriesTarget)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setCountries(data)
            });
    }, [])

    useEffect(() => {
        getCountries()
    }, [getCountries])

    useEffect(() => {
        if (countries.length) {
            setSelectedCountry(countries[0]);
        }
    }, [countries])

    useEffect(() => {
        if (!selectedCountry) {
            return;
        };
        const numbersTarget = new URL(config.API_BASE + "/prices");
        const pricingNumbersParams = new URLSearchParams();
        pricingNumbersParams.set('countryName', selectedCountry.toLowerCase());
        pricingNumbersParams.set('pricingType', "numbers");
        numbersTarget.search = new URLSearchParams(pricingNumbersParams).toString();
        fetch(numbersTarget).then((res) => {
            return res.json();
        }).then((data) => {
            setPricingNumbers(data);
        })
    }, [selectedCountry])

    useEffect(() => {
        if (!selectedCountry) {
            return;
        }
        const fetchData = async () => {
            try {
                const callsTarget = new URL(config.API_BASE + "/prices");
                const pricingCallsParams = new URLSearchParams();
                pricingCallsParams.set('countryName', selectedCountry.toLowerCase());
                pricingCallsParams.set('pricingType', "calls");
                callsTarget.search = new URLSearchParams(pricingCallsParams).toString();
                const response = await fetch(callsTarget);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setPricingCalls(data);
            } catch (error) {
                console.error('Fetch error:', error);
            }
        }
        fetchData();
        const messagingTarget = new URL(config.API_BASE + "/prices");
        const pricingMessagingParams = new URLSearchParams();
        pricingMessagingParams.set('countryName', selectedCountry.toLowerCase());
        pricingMessagingParams.set('pricingType', "messaging");
        messagingTarget.search = new URLSearchParams(pricingMessagingParams).toString();
        fetch(messagingTarget).then((res) => {
            return res.json();
        }).then((data) => {
            setPricingMessaging(data);
        })
    }, [selectedCountry])
    const toggleOptions = (setter) => {
        setter(prev => !prev);
    }
    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
    };
    const handleOptionSelect = (optionTitle) => {
        setSelectedCountry(optionTitle);
        setInputValue("");
        setIsOpenCoutryDropDown(false);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpenCoutryDropDown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div id="pricing" className='pricing' style={{ position: "relative" }}>
            <Header name={"Pricing"} />
            <div className='pricing-header'>
                <div className="ovoky-text-style-title-H1 pricing-title">Plans built to power your business</div>
                <div className="paragraph">
                    <p className="ovoky-text-style-p1-body">Our pricing structure varies based on factors such as call rates,
                        regulatory fees, and taxes, all of which differ by country,
                        making it essential to select your country for accurate pricing tailored to your specific location.
                    </p>
                    <ErrorOutlineIcon />
                </div>
            </div>
            <div className="pricing-content">
                <div className="pricing-drop-down">
                    {
                        countries?.length !== 0 &&
                        <DropDown
                            name={"Country"}
                            theme={"big-drop-down"}
                            themeOptions={"options-big-drop-down"}
                            selectedOption={selectedCountry}
                            handleInputChange={handleInputChange}
                            handleOptionSelect={handleOptionSelect}
                            toggleOptions={() => toggleOptions(setIsOpenCoutryDropDown)}
                            inputValue={inputValue}
                            filteredOptions={
                                countries
                                    .filter(option => option.toLowerCase().includes(inputValue.toLowerCase()))
                                    .map(c => ({ title: c, value: c }))
                            }
                            isOpen={isOpenCountryDropDown}
                            dropDownRef={dropdownRef}
                        />
                    }
                </div>
                <div className="plans">
                    <Plans
                        planName={"Basic"}
                        businessesType={"Small Businesses"}
                        planDescp={"Ideal for individuals and small businesses looking for essential features at an affordable price."}
                        theme={"basic-plan"}
                        handleOpen={handleOpen}
                        numbersOptions={pricingNumbers.map(p => ({ type: p.data.type, pricing: p.data.monthlyFee }))}
                        callsOptions={pricingCalls.map(c => ({ type: c.data.rate.type, inbound: c.data.rate.inbound, price: c.data.rate.outbound.small }))}
                        messagingOptions={pricingMessaging.map(m => ({ incoming: m.data.rate.twoWay, outgoing: m.data.rate.outgoing.small }))}
                        btnBgColor={"var(--dodger-blue)"}
                        btnColor={"var(--b-95)"}
                    />
                    <Plans
                        planName={"Medium"}
                        businessesType={"Medium Businesses"}
                        planDescp={"Perfect for growing businesses that require additional features and scalability."}
                        theme={"medium-plan"}
                        handleOpen={handleOpen}
                        numbersOptions={pricingNumbers.map(p => ({ type: p.data.type, pricing: p.data.monthlyFee }))}
                        callsOptions={pricingCalls.map(c => ({ type: c.data.rate.type, inbound: c.data.rate.inbound, price: c.data.rate.outbound.medium }))}
                        messagingOptions={pricingMessaging.map(m => ({ incoming: m.data.rate.twoWay, outgoing: m.data.rate.outgoing.medium }))}
                        btnBgColor={"var(--green-cyan)"}
                        btnColor={"var(--g-95)"}
                    />
                    <Plans
                        planName={"Premium"}
                        businessesType={"Big Businesses"}
                        planDescp={"Tailored for established businesses demanding high-performance"}
                        theme={"premium-plan"}
                        handleOpen={handleOpen}
                        numbersOptions={pricingNumbers.map(p => ({ type: p.data.type, pricing: p.data.monthlyFee }))}
                        callsOptions={pricingCalls.map(c => ({ type: c.data.rate.type, inbound: c.data.rate.inbound, price: c.data.rate.outbound.large }))}
                        messagingOptions={pricingMessaging.map(m => ({ incoming: m.data.rate.twoWay, outgoing: m.data.rate.outgoing.large }))}
                        btnBgColor={"var(--y-40)"}
                        btnColor={"var(--y-95)"}
                    />
                </div>

            </div>
            {/* <div>
                <Features options={featues} />
            </div> */}
        </div>
    )
}
