import React, { Fragment, useState } from 'react'
import "./one-author.css";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { ReactComponent as AwardsIcon } from '../assets/awardsIcon.svg'
// import { ReactComponent as OvokyHomeIcon } from "./assets/ovokyHome.svg"

export default function OneAuthor({ options }) {
    const [follow, setFollow] = useState(options.map(o => false));
    const onFollow = (index) => {
        setFollow(p => {
            const newValue = [...p];
            newValue[index] = !newValue[index];
            return newValue;
        })
    }
    return (
        <>
            {
                options.map((info, index) => (
                    <div className='one-author' key={index}>
                        <div className="one-author-header">
                            <div className="bg-image"></div>
                            <div className="author-image">
                                <img className='top-border' src="/images/top-border.png" alt="" />
                                <img className='bottom-border' src="/images/bottom-border.png" alt="" />
                                <img src={info.imgAuthor} alt={`author ${info.authorName}`} />
                                <div className='author-name'>
                                    <div>
                                        <h1>{info.authorName}</h1>
                                        <p><span><ArticleOutlinedIcon /> {info.articlesNum} article</span><span><PersonOutlineOutlinedIcon /> {info.followrsNum} Follower</span></p>
                                    </div>
                                    <div>
                                        <button className={follow[index] === true ? "btn button-active" : "btn"} onClick={() => onFollow(index)}>{follow[index] === true ? <>followed <PersonOutlineOutlinedIcon /></> : "follow + "}</button>
                                        {/* <button className="btn"> follow +</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="one-author-content">

                            {
                                info.moreInfo.map((m, i) => (
                                    <div key={i}>
                                        <div className="author-line"></div>
                                        <ul>
                                            <li>Type of Writing:</li>
                                            <p>{m.typeOfWriting}
                                            </p>
                                            <li>Categories of Writing:</li>
                                            <ol>
                                                {
                                                    m.categOfWriting.map((l, inx) => (

                                                        <li key={inx}>{l.li}</li>

                                                    ))
                                                }
                                            </ol>
                                        </ul>
                                        <p>{m.descp}</p>
                                    </div>
                                ))
                            }
                            {
                                info.personalInfo.map((p, i) => (
                                    <div key={i}>
                                        <div>Nationality:<span> {p.nationalite}.</span></div>
                                        <div>Age :<span> {p.age} years old.</span></div>
                                        <div>Profession:<span> {p.profession}.</span></div>
                                        <div>Notable Works:
                                            {
                                                p.notableWorks.map((n, i) => (

                                                    <p key={i}>{n.work}</p>

                                                ))
                                            }
                                        </div>
                                        <div>Awards :
                                            {
                                                p.awards.map((a, i) => (

                                                    <p key={i} className='awards'><AwardsIcon />{a.work}</p>

                                                ))
                                            }
                                        </div>
                                        <div className='biography'>Biography:
                                            {
                                                p.biography.map((b, i) => (
                                                    <Fragment key={i}>
                                                        <p>{b.past}</p>
                                                        <p>{b.present}</p>
                                                    </Fragment>

                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }

        </>
    )
}
