import React from 'react'
import "./tags.css";

export default function Tags({ tags, title }) {
    return (
        <div className='tags'>
            <h3>
                {title}
            </h3>
            <div  className="taglist">
                {
                    tags.map((t , i)=> (
                        <div key={i}>{t.tag}</div>
                    ))
                }
            </div>
        </div>
    )
}
