import "./selection-bar.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function SelectionBar({ open, title, handleClick }) {
  return (
        <div className="selection-bar">
          <div className={open ? "ovoky-text-style-body-small active" :"ovoky-text-style-body-small"} onClick={handleClick}> {title} {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} </div>
          <div className="selection-bar-line"></div>
        </div>
  )
}
