import React from 'react'

export default function Messaging({ options }) {
    if (options.length === 0) {
        return;
    }
    return (
        <div className="item">
            <p>Messaging</p>
            {
                options.map((option, index) => (
                    <div className="item-content" key={index}>
                        <ul>
                            <li className="ovoky-text-style-body-small" ><span >Incoming SMS</span></li>
                        </ul>
                        <p><span className="ovoky-text-style-p1-body-medium">{option.incoming} € </span>/ SMS</p>
                        <div className="plan-line"></div>
                        <ul>
                            <li className="ovoky-text-style-body-small" ><span >Outgoing SMS</span></li>
                        </ul>
                        <p><span className="ovoky-text-style-p1-body-medium">{option.outgoing} € </span>/ SMS</p>
                    </div>
                ))
            }
        </div>
    )
}
