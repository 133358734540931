import React, { Children } from 'react'
import "./nav-bar.css";
export default function NavBar({children}) {
  return (
    <div id='home' className='nav-bar'>
        {
          Children.map(children, child =>(
            <>{child}</>
          ))
        }
    </div>
  )
}
