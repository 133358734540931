import React, { useState } from 'react'
import Accordion from '../Components/Accordion'
import Header from '../Components/Header'
import "./frequently-a-q.css";

export default function FrequentlyAQ({ fastAQ , srcImg}) {
    const [activeIndex, setActiveIndex] = useState();
    return (
        <div className='fast-a-q' style={{ position: "relative" }}>
            <Header name={"FAQ"} />
            <div className="faq-container">
                <div className="faq-title">
                    <div className='ovoky-text-style-title-H1 big-title'>
                        You have more questions ?<br></br> Contact Us !
                    </div>
                    <p className='ovoky-text-style-body-small parag'>Feel free to reach out to our
                        dedicated customer support team for further assistance. We're here to help you find the perfect plan that suits your needs and answer any inquiries you may have.</p>
                </div>
                <div className='accordions-container'>
                    {
                        fastAQ.map((item, index) => (
                            <Accordion key={index} title={item.title} theme={"ovoky-text-style-p1-body gradient-bg-theme"} open={activeIndex === index} openStyle={activeIndex === index && "open"} handleClick={() => setActiveIndex((prev) => prev !== index && index )}>
                                <div className='show-parag'><p>{item.descp}</p></div>
                            </Accordion>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}
