import React, { Fragment } from 'react'

export default function Numbers({ options }) {

    return (
        <div className="item numbers">
            <p>Numbers</p>
            <div className="item-content">
                {
                    options.map((option, index) => (
                        <Fragment key={index}>
                            <ul >
                                <li className="ovoky-text-style-body-small" ><span >{option.type} Numbers</span></li>
                            </ul>
                            <p><span className="ovoky-text-style-p1-body-medium">{option.pricing} € </span>  Month / Number</p>
                            <div className="plan-line"></div>
                        </Fragment>
                    ))
                }
            </div>
        </div>
    )
}
