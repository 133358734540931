import "./search-bar.css";
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

import search from '../data/search.json';
import SelectionBar from "./SelectionBar";

export default function SearchBar({ onClick, show, activeIndex, setActiveIndex, onShowSearchBar }) {
  return (
    <>
      <div className='search-bar'>
        {
          show ?
            <>
              {
                search.map((s, i) => (
                  <SelectionBar key={i} title={s.title} open={activeIndex === i} handleClick={() => { setActiveIndex((prev) => prev !== i && i) }} />
                ))
              }
            </>
            :
            <input type="text" placeholder="Search..." />
        }
        <button className={show === true ? "setting-icon active" : "setting-icon"} onClick={onClick}><TuneIcon /></button>
        <button onClick={onShowSearchBar} className={show === false ? "search-icon active" : "search-icon"}><SearchIcon /></button>
      </div>
      <div className="search-bar-phone">
        <input type="text" placeholder="Search..." />
        <button className={show === true ? "setting-icon active" : "setting-icon"} onClick={onClick}><TuneIcon /></button>
        <button onClick={onShowSearchBar} className={show === false ? "search-icon active" : "search-icon"}><SearchIcon /></button>
      </div>
    </>
  )
}
