import React from 'react'
import OneAuthor from '../Components/OneAuthor'
import ArticlesList from './ArticlesList'
import Article from '../Components/Article'
import Subscribe from './Subscribe'
import FrequentlyAQ from './FrequentlyAQ'
import Tags from '../Components/Tags';
import articles from "../data/articles.json";
import authors from "../data/authors.json"
import frequentlyAQ from "../data/frequentlyAQ";
import authorsInfo from "../data/authorsInfo.json";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function OneAuthorPage() {

  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get('id');
  let author = [];
  let tags = [];
  let filterArticles = [];

  if (authorsInfo.map(author => author.id).includes(parseInt(id)) === true) {
    author = authorsInfo.filter(info => info.id === parseInt(id));
    tags = authors.find(a => a.id === parseInt(id));
    filterArticles = articles.filter(art => art.idAuthor === parseInt(id));
  } 
  return (
    <>
      {
        author.length > 0 ?
          <>
            <OneAuthor options={author} />
            {
              filterArticles.length !== 0 &&
              <>
                <ArticlesList title={"Last Articles"} fontTitle={"28px"} titleColor={"var(--light-blue)"}>
                  <div className="container">
                    <Article options={filterArticles} limit={3} />
                  </div>
                </ArticlesList>
                {/* <a className="article-link" href="/blog/articles">Load More <KeyboardArrowDownIcon /></a> */}
              </>
            }
            <Tags title={"Tags related to the author "} tags={tags.tags} />
            <Subscribe />
            <FrequentlyAQ fastAQ={frequentlyAQ} />
          </>
          :
          <div className='not-found'>
            <h1>Not found </h1>
          </div>
      }
    </>
  )
}
