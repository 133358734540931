import React from 'react'

export default function Calls({ options }) {
    if (options.length === 0) {
        return;
    }

    return (
        <div className="item">
            <p>Voice</p>
            {
                options.map((option, index) => (
                    <div className="item-content" key={index}>
                        <li className="ovoky-text-style-body-small" ><span >{option.type}</span></li>
                        <ul>
                            <li className="ovoky-text-style-body-small" ><span >Incoming Voice</span></li>
                        </ul>
                        <p><span className="ovoky-text-style-p1-body-medium"> {option.inbound} € </span>/ Minute</p>
                        <div className="plan-line"></div>
                        <ul>
                            <li className="ovoky-text-style-body-small" ><span >Outgoing Voice</span></li>
                        </ul>
                        <p><span className="ovoky-text-style-p1-body-medium">{option.price} € </span>/ Minute</p>
                    </div>
                ))
            }

        </div>
    )
}
