import Header from "../Components/Header";
import MainProduct from "../Components/MainProduct";
import "./product.css";


export default function Product() {
     
    return (
        <div id="product" className="product" style={{position: "relative"}}>
            <Header name={"Product"}/>
            <div className="product-content">
                <MainProduct
                    titles={[
                        {
                            name : "Numbers"
                        },
                        {
                            name: "Voice"
                        },
                        {
                            name: "Messaging"
                        }
                    ]}
                />
            </div>
        </div>
    )
}
