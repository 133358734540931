import "./drop-down.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function DropDown({ filteredOptions, name,dropDownRef, onclick, theme, themeOptions, isOpen, selectedOption, toggleOptions, handleInputChange, handleOptionSelect, inputValue }) {
    const classList2 = ["options", themeOptions];

    let open = "";
    if (isOpen === true) {
        open = "drop-down-open active";
    } else {
        open = "drop-down-close";
    }
    const classList = ["drop-down", theme, open];

    return (
        <div style={{ position: "relative", display: "flex", flexDirection : "column", gap : "5px" }} ref={dropDownRef}>
            <p className='drop-down-name'>{name}</p>
            <div className={classList.join(" ")} onClick={toggleOptions}>
                {selectedOption} {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </div>
            {isOpen && (
                <div className={classList2.join(" ")}>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Type to search..."
                        className="autocomplete-input"
                    />
                    {filteredOptions.map((option, index) => (
                        <div
                            key={index}
                            className="option"
                            onClick={() => {
                                handleOptionSelect(option.title); if (!(typeof onChange === "function")) {
                                    return;
                                } onclick()
                            }}
                            value={option.value}
                        >
                            {option.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
