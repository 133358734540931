import { useState } from "react";
import "./author.css";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
export default function Author({ options }) {
    const [follow, setFollow] = useState(options.map(o => false));
    const onFollow = (index) => {
        setFollow(p => {
            const newValue = [...p];
            newValue[index] = !newValue[index];
            return newValue;
        })
    }
    
    return (
        <>
            {
                options.map((option, index) => (
                    <div className="author" key={index}>
                        <div className="head">
                            <div>
                                <a href={"/blog/author?id="+option.id}>
                                    <img src={option.authorImg} alt="" />
                                    <div>
                                        <h3>{option.authorName}</h3>
                                        <p>{option.numberArticle} Articles</p>
                                        <p>{option.numberFollower} Followers</p>
                                    </div>
                                </a>
                            </div>
                            <div>
                                <button className={follow[index] === true ? "btn button-active" : "btn"} onClick={() => onFollow(index)}>{follow[index] === true ? <>followed <PersonOutlineOutlinedIcon /></> : "follow + "}</button>
                            </div>
                        </div>
                        <div className="content">
                            <p>{option.descp}</p>
                        </div>
                        <div className="foot">
                            {
                                option.tags.map((t, i) => (
                                    <p key={i}>#{t.tag}</p>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </>
    )
}
