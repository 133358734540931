import React, { useEffect, useState } from 'react';
import './carousel.css';
import Button from './Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const Carousel = ({ images, options }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 6000);

        return () => clearInterval(interval);
    }, [currentIndex, images.length]);


    return (
        <>
            <div className='hero-container'>
                <div className="content-container">
                    {
                        options.map((option, index) => (
                            <div key={index}
                                className={index === currentIndex ? "text active" : "text"}
                                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                            >
                                <div className="header">
                                    <h1 className='ovoky-text-style-H0-title-hero'>{option.h1}</h1>
                                    <h2 className='ovoky-text-style-H0-title-hero-2'>{option.h2}</h2>
                                </div>
                                <p className='ovoky-text-style-p1-body'>{option.p}</p>
                            </div>
                        ))
                    }
                </div>
                <div className='div-button'>
                    <Button href={"/#form"} name={"Talk to an expert"} theme={"ovoky-comp-button-blue-bg"} icon={<ArrowRightAltIcon />} />
                </div>
            </div>
            <div className="carousel">
                <div className="carousel-container">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={index === currentIndex ? 'carousel-slide active' : 'carousel-slide'}
                            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                        >
                            <img src={image.src} alt={image.alt} style={{ width: image.width, height: image.height, transform: image.transform }} />
                        </div>
                    ))}
                </div>
                <div className="indicators">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            className={index === currentIndex ? 'indicator active' : 'indicator'}
                            onClick={() => goToSlide(index)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default Carousel;