import "./article.css";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import { useState } from "react";
export default function Article({ options, limit }) {
    const [save, setSave] = useState(options.map(o => false));
    const OnSaved = (index) => {
        setSave(prev => {
            const newValue = [...prev];
            newValue[index] = !newValue[index];
            return newValue;
        })
    }
    const [link, setLink] = useState(options.map(o => false));
    const OnLink = (index) => {
        setLink(prev => {
            const newValue = [...prev];
            newValue[index] = true;
            return newValue;
        })
        setTimeout(() => {
            setLink(prev => {
                const newValue = [...prev];
                newValue[index] = false;
                return newValue;
            })
        }, 1000)
    }
    return (
        <>
            {
                (() => {
                    const elements = [];
                    for (let i = 0; i < limit; i++) {
                        const option = options[i];
                        elements.push(
                            <div className='article' key={i}>
                                <div className="bg-img">
                                    <div className="article-img">
                                        <img src={option.imgArticle} alt="" />
                                    </div>
                                    <div className="author-info">
                                        <p><img src={option.authorImg} alt="" />{option.authorName}</p>
                                    </div>
                                    <div className={save[i] === true ? "saved" : "save-img"} onClick={() => OnSaved(i)}>
                                        <BookmarkIcon /><span>{save[i] === true ? "saved" : ""}</span>
                                    </div>
                                    <div className={link[i] === true ? "linked" : "link-img"} onClick={() => OnLink(i)}>
                                        <InsertLinkOutlinedIcon /><span>{link[i] === true ? "copied" : ""}</span>
                                    </div>
                                    <div className="time">
                                        <p><HourglassBottomOutlinedIcon />{option.minute} Minutes</p>
                                        <p><AccessAlarmOutlinedIcon />{option.date}</p>
                                    </div>
                                </div>
                                <div className="article-info" style={{ background: `linear-gradient(170deg, ${option.bgColor} 0%, #F3F3F4 50%)` }}>
                                    <a href={`/blog/article?id=${option.idArticle}`}>
                                        <div>
                                            <p className="article-title" style={{ backgroundColor: option.titleColor }}>{option.name}</p>
                                        </div>
                                        <h3>{option.title}</h3>
                                        <p className="article-descp">{option.descp}</p>
                                        <div className="article-reaction">
                                            <div className="view"><RemoveRedEyeOutlinedIcon />{option.viewNum}</div>
                                            <div className="like"><FavoriteBorderOutlinedIcon />{option.likeNum}</div>
                                            <div className="share"><ReplyOutlinedIcon />{option.shareNum}</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        );
                    }
                    return elements;
                })()
            }
        </>
    )
}
