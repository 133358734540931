import React from 'react'
// import { ReactComponent as LineIcon} from "../assets/line.svg"
import "./menuIcon.css";

export default function MenuIcon ({handleClick, isOpen}){
 

  return (
    <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={handleClick}>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};

