import "./footer.css";

export default function Footer() {
    return (
            <div className="footer">
                <div className="bg-repeat-footer"></div>
                <div className="bg-linear-footer"></div>
                <div className="first-div">
                    <div className="logo">
                        <img src="/images/black-logo.png" alt="Logo" />
                    </div>
                    <div className="list">
                        <ul>
                            <li><a className='link' href='/'>Home</a></li>
                            <li><a className='link' href='/#product'>Services</a></li>
                            <li><a className='link' href='/#pricing'>Pricing</a></li>
                            <li><a className='link' href='/blog'>Blog</a></li>
                        </ul>
                    </div>
                </div>
                <div className="second-div">
                    <p>© Ovoky Corp Inc. 2024</p>
                    <div className="list">
                        <ul>
                            <li><a className='link' href='https://www.facebook.com/ovoky'><img src="/images/fb.png" alt="facebook" /></a></li>
                            <li><a className='link' href='https://fr.linkedin.com/'><img src="/images/linkdin.png" alt="linkdin" /></a></li>
                            <li><a className='link' href='https://www.twitter.com'><img src="/images/twitter.png" alt="twitter" /></a></li>
                            <li><a className='link' href='https://www.instagram.com'><img src="/images/insta.png" alt="instagram" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
    )
}
