import React, { useRef, useState } from 'react'
import "./main-blog.css";
// import SelectionBar from './SelectionBar';
import search from '../data/search.json';
import Calendar from './Calendar';
import AuthorCard from './AuthorCard';
import authors from "../data/authors.json";
import SearchBar from './SearchBar';
import SelectionBar from './SelectionBar';

export default function MainBlog() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [selected, setSelected] = useState(search.map((s, i) => s.descp.map(d => false))[0])

    const onSelect = (index) => {
        setSelected(prev => {
            const newValue = [...prev];
            newValue[index] = !newValue[index]
            return newValue
        })
    }
    const scrollableDivRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollableDivRef.current.offsetLeft);
        setScrollLeft(scrollableDivRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.pageX - scrollableDivRef.current.offsetLeft;
        const walk = (x - startX) * 1;
        scrollableDivRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };
    const [show, setShow] = useState(false);
    const onClick = () => {
        setShow(p => !p)
    }
    const onShowSearchBar = () => {
        setShow(false)
    }
    return (
        <>


            <div className="first-div" style={show === true ? {padding: "100px 135px 100px 135px"} : {padding: "200px 135px 100px 135px"}}>
                {
                    show === false &&
                    <h1 className='ovoky-text-style-H0-title-hero'>Ovoky Search</h1>
                }
                <div className='selection-header'>
                    <div className="selection-div">
                        <SearchBar show={show} onClick={onClick} activeIndex={activeIndex} setActiveIndex={setActiveIndex} onShowSearchBar={onShowSearchBar} />
                        {
                            show !== false &&
                            <button className="ovoky-text-style-body-small selection-button">Confirm</button>
                        }
                    </div>

                    {
                        show === true &&
                        <>
                            <div className='selection-box' style={activeIndex === 0 ? { display: "flex" } : { display: "none" }}>
                                <div className='selection-content' >
                                    {
                                        search[0].descp.map((d, index) => (
                                            <div className={selected[index] === true ? "selected" : null} onClick={() => onSelect(index)} key={index}>{d.categ}</div>
                                        ))
                                    }
                                </div>
                                <div className='confirm-btn'>
                                    <button>Confirm</button>
                                </div>
                            </div>

                            <div style={activeIndex === 1 ? { display: "flex", width: "100%" } : { display: "none" }}>
                                <Calendar />
                            </div>
                            <div className='selection-box' style={activeIndex === 2 ? { display: "flex" } : { display: "none" }}>
                                <div className='selection-content' >
                                    {
                                        search[2].descp.map((d, index) => (
                                            <div key={index} className={selected[index] === true ? "selected" : null} onClick={() => onSelect(index)}>{d.categ}</div>
                                        ))
                                    }
                                </div>
                                <div className='confirm-btn'>
                                    <button>Confirm</button>
                                </div>
                            </div>
                            <div className='selection-box' style={activeIndex === 3 ? { display: "flex" } : { display: "none" }}>
                                <div
                                    ref={scrollableDivRef}
                                    style={{
                                        width: '100%',
                                        display: "flex",
                                        overflowX: 'hidden',
                                        cursor: isDragging ? 'grabbing' : 'grab',
                                        gap: "20px"
                                    }}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    onMouseLeave={handleMouseUp}
                                >
                                    <AuthorCard options={authors} />
                                </div>
                                <div className='confirm-btn'>
                                    <button>Confirm</button>
                                </div>
                            </div>
                            {
                                show !== false &&
                                <button className="ovoky-text-style-body-small selection-button-tablette">Confirm</button>
                            }
                        </>
                    }
                    <div className='trends' style={show === true ?{alignSelf : "flex-start"}: {alignSelf : "center"}}>
                        <h2><img src="/images/Fire_light.png" alt="" />Trends</h2>
                        <div className="button-list">
                            <button>Metaverse</button>
                            <button>Sustainability</button>
                            <button>Biotech</button>
                            <button>Renewables</button>
                            <button>Digitalization</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="first-div-phone">
                <div className="selection-div">
                    <SearchBar show={show} onClick={onClick} activeIndex={activeIndex} setActiveIndex={setActiveIndex} onShowSearchBar={onShowSearchBar} />
                    {
                        show &&
                        <>
                            <div className='selection-bar-container'>
                                {
                                    search.map((s, i) => (
                                        <SelectionBar key={i} title={s.title} open={activeIndex === i} handleClick={() => { setActiveIndex((prev) => prev !== i && i) }} />
                                    ))
                                }
                            </div>

                            <div className='selection-box' style={activeIndex === 0 ? { display: "flex", width: "100%" } : { display: "none" }}>
                                <div className='selection-content' >
                                    {
                                        search[0].descp.map((d, index) => (
                                            <div className={selected[index] === true ? "selected" : null} onClick={() => onSelect(index)} key={index}>{d.categ}</div>
                                        ))
                                    }
                                </div>
                                <div className='confirm-btn'>
                                    <button>Confirm</button>
                                </div>
                            </div>

                            <div style={activeIndex === 1 ? { display: "flex", width: "100%" } : { display: "none" }}>
                                <Calendar />
                            </div>
                            <div className='selection-box' style={activeIndex === 2 ? { display: "flex", width: "100%" } : { display: "none" }}>
                                <div className='selection-content' >
                                    {
                                        search[2].descp.map((d, index) => (
                                            <div key={index} className={selected[index] === true ? "selected" : null} onClick={() => onSelect(index)}>{d.categ}</div>
                                        ))
                                    }
                                </div>
                                <div className='confirm-btn'>
                                    <button>Confirm</button>
                                </div>
                            </div>
                            <div className='selection-box' style={activeIndex === 3 ? { display: "flex", width: "100%" } : { display: "none" }}>
                                <div
                                    ref={scrollableDivRef}
                                    style={{
                                        width: '100%',
                                        display: "flex",
                                        overflowX: 'hidden',
                                        cursor: isDragging ? 'grabbing' : 'grab',
                                        gap: "20px"
                                    }}
                                    onMouseDown={handleMouseDown}
                                    onMouseMove={handleMouseMove}
                                    onMouseUp={handleMouseUp}
                                    onMouseLeave={handleMouseUp}
                                >
                                    <AuthorCard options={authors} />
                                </div>
                                <div className='confirm-btn'>
                                    <button>Confirm</button>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}



// import React from 'react'
// import "./main-blog.css";
// import SearchBar from "../Components/SearchBar";

// export default function MainBlog() {
//     return (
//         <div className="first-div">
//             <h1>Ovoky’s Blog</h1>
//             <p>This blog is your gateway to a world where technology meets innovation,
//                 science unveils wonders, and creativity knows no bounds. Join us on a journey through the latest advancements,
//                 insightful discoveries, and inspiring creations that shape our future.
//             </p>
//             <SearchBar />
//
//         </div>
//     )
// }
