import "./subscribe.css";
import Header from '../Components/Header'
import Button from "../Components/Button";

export default function Subscribe() {
    return (
        <div className="subscribe" style={{ position: "relative" }}>
            <Header name={"Sub"} />
            <div className="sub-content">
                <div>
                    <div className="ovoky-text-style-title-H1 sub-title">Subscribe to our newsletter</div>
                    <p>be the first to get the exclusive offers and the latest news .</p>
                    <input type="text" placeholder="Entre your email Address " />
                    <Button name={"Subscribe"} theme={"ovoky-comp-sub-button"} />
                </div>
                <div className="sub-img">
                    <img className="img" src="/images/sub.png" alt="" />
                    {/* <img className="hovered" src="/images/sub-hovered.png" alt="" /> */}
                </div>
            </div>
        </div>
    )
}
