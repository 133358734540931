import './App.css';
import './global.css';
import "./reset.css";
import "./text-style.css";
import NavBar from './Pages/NavBar';
import HeroSection from './Pages/HeroSection';
import Product from './Pages/Product';
import Pricing from './Pages/Pricing';
import Reviews from './Pages/Reviews';
import Form from './Pages/Form';
import frequentlyAQ from "./data/frequentlyAQ";
import Footer from './Pages/Footer';
import { useCallback, useEffect, useRef, useState } from 'react';
import PopUp from './Components/PopUp';
import Button from './Components/Button';
import { BrowserRouter, Route } from 'react-router-dom';
import { Routes } from "react-router-dom";
import Blog from './Pages/Blog';
import FrequentlyAQ from './Pages/FrequentlyAQ';
import ArticlesList from './Pages/ArticlesList';
import Article from "./Components/Article";
import articles from "./data/articles.json";
import Author from "./Components/Author";
import authors from "./data/authors.json"
import OneAuthorPage from './Pages/OneAuthorPage';
import NotFoundPage from './Pages/NotFoundPage';
import OneArticlePage from './Pages/OneArticlePage';
import { ReactComponent as BlogIcon } from "./assets/blog.svg"
import { ReactComponent as ProductIcon } from "./assets/product.svg"
import { ReactComponent as PricingIcon } from "./assets/pricing.svg"
import { ReactComponent as ContactIcon } from "./assets/contact.svg"
import { ReactComponent as BlogHomeIcon } from "./assets/blogHome.svg"
import { ReactComponent as ArticlesIcon } from "./assets/articlesIcon.svg"
import { ReactComponent as AuthorsIcon } from "./assets/authorsIcon.svg"
import { ReactComponent as OvokyHomeIcon } from "./assets/ovokyHome.svg"
import MenuIcon from './Components/MenuIcon';
function App() {
  const [openList, setOpenList] = useState(false);
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  let signUpRef = useRef(null);

  const openPopup = (setter1) => {
    setter1(true);
    setOpenList(false)
  };

  const closePopup = () => {
    setIsSignUpOpen(false);
  };
  if (window.screen.width < 1300) {
    signUpRef = null;
  }
  const handleClickOutside = useCallback((event) => {
    if (signUpRef === null) {
      return;
    }
    if (signUpRef.current && !signUpRef.current.contains(event.target)) {
      closePopup();
    }
  }, []);

  useEffect(() => {
    if (isSignUpOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSignUpOpen, handleClickOutside]);
  const onShowList = () => {
    setOpenList(prev => !prev)
  }
  const onclick = () => {
    setOpenList(false)
    closePopup();
  }
  return (
    <BrowserRouter>

      <div className="App" >
        {isSignUpOpen && (
          <div className="overlay-App">
          </div>
        )}
        {(window.location.pathname.startsWith("/blog")) ? (
          openList &&
          <div className="overlay-App-phone-blog">
          </div>
        ) : (
          openList &&
          <div className="overlay-App-phone">
          </div>
        )}
        {
          window.location.pathname.startsWith("/blog") ?
            <NavBar>
              <div className='blog-nav-bar'>
                <div className="logo">
                  <a href='/blog'>
                    <p>
                      <img src="/images/logo.png" alt="Logo" />Blog
                    </p>
                  </a>
                </div>
                <div className="list">
                  <ul>
                    <li><a className={window.location.pathname === "/blog" ? "link active" : "link"} href='/blog'>Home</a></li>
                    <li><a className={window.location.pathname === "/blog/articles" ? "link active" : "link"} href='/blog/articles'>Articles</a></li>
                    <li><a className={window.location.pathname === "/blog/authors" ? "link active" : "link"} href='/blog/authors'>Authors</a></li>
                    <li><a className={window.location.pathname === "/" ? "link active" : "link"} href='/'>Ovoky Home</a></li>
                  </ul>
                </div>
                <div className='phone-list'>
                  <div className='list-icon'><MenuIcon handleClick={onShowList} isOpen={openList} /></div>
                  {
                    openList === true &&
                    <div className='phone-list-container'>
                      <div className="link-container">
                        <li><a className="link" href='/blog' onClick={onclick}><BlogHomeIcon /> Home</a></li>
                        <li><a className='link' href='/blog/articles' onClick={onclick}><ArticlesIcon /> Articles</a></li>
                        <li><a className='link' href='/blog/authors' onClick={onclick}><AuthorsIcon /> Authors</a></li>
                        <li><a className='link' href='/'><OvokyHomeIcon /> Ovoky home</a></li>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </NavBar>
            : <NavBar>
              <div className='container'>
                <div className="logo">
                  <a href='/'>
                    <img src="/images/logo.png" alt="Logo" />
                  </a>
                </div>
                <div className="list">
                  <ul>
                    <div className="link-container">
                      <li><a className="link" href='/#product'>Product</a></li>
                      <li><a className='link' href='/#pricing'>Pricing</a></li>
                      <li><a className='link' href='/#form'>Contact</a></li>
                      <li><a className='link' href='/blog'>Blog</a></li>
                    </div>
                    <div className="sign-container">
                      <li id='try-theme'><button className={isSignUpOpen === true ? "active" : null} onClick={() => openPopup(setIsSignUpOpen)}>Try for free</button></li>
                      <li id='sign-theme'><button><a href="https://sip2.ovoky.io/">Sign-in</a></button></li>
                      {/* <li id='try-theme'><a href="/#form"><button>Talk to an expert</button></a></li> */}
                    </div>
                  </ul>
                </div>
                <div className='phone-list'>
                  <li id='try-theme'><button className={isSignUpOpen === true ? "active" : null} onClick={() => openPopup(setIsSignUpOpen)}>Try for free</button></li>
                  <div className='list-icon'><MenuIcon handleClick={onShowList} isOpen={openList} /></div>

                  {
                    openList === true &&
                    <div className='phone-list-container'>
                      <div className='sign-container'>
                        <li id='sign-theme'><button><a href="https://sip2.ovoky.io/">Sign-in</a></button></li>
                      </div>
                      <div className="link-container">
                        <li><a className="link" href='/#product' onClick={onclick}><ProductIcon />Product</a></li>
                        <li><a className='link' href='/#pricing' onClick={onclick}><PricingIcon /> Pricing</a></li>
                        <li><a className='link' href='/#form' onClick={onclick}><ContactIcon /> Contact</a></li>
                        <li><a className='link' href='/blog' ><BlogIcon /> Blog</a></li>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </NavBar>
        }
        <Routes>
          <Route path={"/"} element={
            <>
              <HeroSection />
              <PopUp open={isSignUpOpen}
                popUpRef={signUpRef}
                closePopUp={closePopup}>
                <h2>Create your account</h2>
                <h3>Sign up</h3>
                <div className="under-line"></div>
                <form action="">
                  <div>
                    <input type="text" id='FName' placeholder='First Name' />
                    <input type="text" id='LName' placeholder='Last Name' />
                  </div>
                  <input type="email" id='email' placeholder='Email' />
                  <input type="text" id='password' placeholder='Password' />
                  <Button name={"Sign-up"} theme={"ovoky-comp-button-blue-big-button"} />
                </form>
              </PopUp>
              <Product />
              <Pricing handleOpen={() => openPopup(setIsSignUpOpen)} />
              <Reviews />
              <FrequentlyAQ fastAQ={frequentlyAQ} srcImg={"/images/fleche.png"} />
              <Form onOpenPopUp={() => openPopup(setIsSignUpOpen)} />
            </>
          } />
          <Route path={"/blog"} element={<Blog />} />

          <Route path='/blog/articles' element={
            <>
              <ArticlesList title={"Articles List"} titleColor={"var(--dark-cyan)"}>
                <div className="container" style={{ height: "100%" }}>
                  <Article options={articles} limit={articles.length} />
                </div>
              </ArticlesList>
              <FrequentlyAQ fastAQ={frequentlyAQ} />
            </>

          } />
          <Route path='/blog/authors' element={
            <>
              <ArticlesList title={"Author List"} titleColor={"#004D33"}>
                <div className="container">
                  <Author options={authors} />
                </div>
              </ArticlesList>
              <FrequentlyAQ fastAQ={frequentlyAQ} />
            </>
          } />
          <Route path={`/blog/author`} element={
            <OneAuthorPage />
          } />
          <Route path='/blog/not-found' element={<NotFoundPage />} />
          <Route path='/blog/article' element={<OneArticlePage />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter >
  );
}

export default App;
