import React, { useCallback, useEffect, useState } from 'react';
import './circularMenu.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropDown from './DropDown';
// import {ReactComponent as OvokyIcon} from '../assets/ovokyIcon.svg';
// import {ReactComponent as ArticleIcon} from '../assets/articleIcon.svg';
// import {ReactComponent as AuthorIcon} from '../assets/authorIcon.svg';

export default function CircularMenu({ setShowSearchBar }) {
  const ovokyChoice = ["Search", "Culture", "Technology", "Digital", "ovoky choice", "Science", "Innovation", "AI"];
  const [currentChoiceIndex, setCurrentChoiceIndex] = useState(4);
  const [currentChoice, setCurrentChoice] = useState(ovokyChoice[currentChoiceIndex])
  const onIncrement = () => {
    setCurrentChoiceIndex((prevIndex) => (prevIndex + 1) % ovokyChoice.length);
  };

  const onDecrement = () => {
    setCurrentChoiceIndex((prevIndex) => (prevIndex - 1 + ovokyChoice.length) % ovokyChoice.length);
  };
  const onclick = (index) => {
    setCurrentChoiceIndex(index)
  }
  const onShowSearchBar = useCallback(() => {
    if (currentChoiceIndex === 0) {
      setShowSearchBar(p => !p)
    }
    else {
      setShowSearchBar(false)
    }
  }, [currentChoiceIndex, setShowSearchBar])
  useEffect(() => {
    onShowSearchBar();
  }, [onShowSearchBar])
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const toggleOptions = () => {
    setIsOpenDropDown(prev => !prev);
  }
  const handleOptionSelect = (optionTitle) => {
    setCurrentChoice(optionTitle)
    setIsOpenDropDown(false)
  }
  useEffect(() => {
    if (currentChoice === "Search") {
      setShowSearchBar(true)
    }
    else {
      setShowSearchBar(false)
    }

  }, [setShowSearchBar, currentChoice])
  return (
    <>


      <div className="choice">
        {/* <button className='ovoky-icon'><OvokyIcon /></button>
      <button className='article-icon'><ArticleIcon /></button>
      <button className='author-icon'><AuthorIcon /></button> */}
        <div className="cercle"></div>
        <div className="box-choice">
          {
            ovokyChoice.map((choice, index) => (
              <p className={currentChoiceIndex === index ? "chosen" : ""}
                onClick={() => { onclick(index); onShowSearchBar() }} key={index}
                style={currentChoiceIndex === -1 ? {} : { filter: `blur(${Math.abs((index - currentChoiceIndex)) * 0.2}px)`, opacity: `${1 - Math.abs((index - currentChoiceIndex)) * 0.15}`, transform: `rotate(${(index - currentChoiceIndex) * 15}deg)`, fontSize: `${Math.abs((index - currentChoiceIndex) * 2.5) + 20}px` }} >
                {choice}
              </p>
            ))
          }
          <div>
            <div className='buttons'>
              <button disabled={currentChoiceIndex === ovokyChoice.length - 1} onClick={onIncrement}><KeyboardArrowUpIcon /></button>
              <button disabled={currentChoiceIndex === 0} onClick={onDecrement}><KeyboardArrowDownIcon /></button>
            </div>
          </div>
        </div>
      </div>
      <div className='drop-down-container'>
        <DropDown
          filteredOptions={ovokyChoice.map((o) => ({ title: o, value: o }))}
          selectedOption={currentChoice}
          theme={"ovoky-choice-drop-down"}
          themeOptions={"options-ovoky-choice-drop-down"}
          handleOptionSelect={handleOptionSelect}
          toggleOptions={toggleOptions}
          isOpen={isOpenDropDown}
          onclick={onShowSearchBar}
        />

      </div>
    </>
  );
};
