import "./form.css";
import Header from '../Components/Header'
import Button from "../Components/Button";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useState } from "react";
import config from "../config";
import { ReactComponent as ErrorIcon } from "../assets/error.svg";
import { ReactComponent as SuccessIcon } from "../assets/success.svg";
export default function Form({ onOpenPopUp }) {
    const [fields, setFields] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
    });
    const [message, setMessage] = useState('');
    const OnChangeInputValue = (event) => {
        setFields({ ...fields, [event.target.name]: event.target.value })
    }
    const OnSubmit = async (e) => {
        try {
            e.preventDefault();
            const emailTarget = new URL(config.API_BASE + "/leads/new");
            const response = await fetch(emailTarget, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(fields),
            });

            if (!response.ok) {
                throw new Error(`HTTP error status: ${response.status}`);
            };

            const responseData = await response.json();
            console.log(responseData);
            setMessage('Your form has been successfully submitted. Thank you !')
        } catch (err) {
            console.error("Error:", err.message);
            setMessage('Oops! something was wrong, please resent the message.')
        }
    };
    const onTryAgain = () => {
        setMessage('')
        setFields({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            message: ""
        });
    }
    return (
        <div id="form" className='form-container' style={{ position: "relative" }}>
            <Header name={"Form"} />
            <div className="form-content">
                <div>
                    <div className="ovoky-text-style-title-H1">Want to get in touch ? <br /> Drop a line </div>
                    <Button name={"Start for free"} theme={"ovoky-comp-button-big-blue-bg"} icon={<ArrowRightAltIcon />} onClick={onOpenPopUp} />
                </div>
                <div className="line"></div>
                {
                    message ?
                        <div className="messages">
                            {message.includes("successfully")
                                ?
                                <div className="success">
                                    <SuccessIcon />
                                    <p className="success-message">
                                        {message}
                                    </p>
                                    <Button btnColor={"var(--g-95)"} btnBgColor={"var(--green-cyan)"} href={"#pricing"} name={"Check prices"} theme={"ovoky-comp-button-small-button"} />
                                </div>
                                :
                                <div className="error">
                                    <ErrorIcon />
                                    <p className="error-message">
                                        {message}
                                    </p>
                                    <Button onClick={onTryAgain} btnColor={"#FFE5EA"} btnBgColor={"#FF002E"} name={"Try again"} theme={"ovoky-comp-button-small-button"} />
                                </div>
                            }
                        </div>
                        : <div className="form">
                            <form action="" onSubmit={OnSubmit}>
                                <div>
                                    <label htmlFor="Fname" className="ovoky-text-style-p1-body">First Name</label>
                                    <input type="text" name="firstName" onChange={OnChangeInputValue} required />
                                    <label htmlFor="Lname" className="ovoky-text-style-p1-body">Last Name</label>
                                    <input type="text" name="lastName" onChange={OnChangeInputValue} required />
                                    <label htmlFor="email" className="ovoky-text-style-p1-body">Email</label>
                                    <input type="eamil" name="email" onChange={OnChangeInputValue} required />
                                    <label htmlFor="phone" className="ovoky-text-style-p1-body">Phone</label>
                                    <input type="text" name="phone" onChange={OnChangeInputValue} required />
                                </div>
                                <div>
                                    <label htmlFor="message" className="ovoky-text-style-p1-body">Message</label>
                                    <textarea placeholder="type your message here" type="text" id="message" name="message" maxLength={500} onChange={OnChangeInputValue} required />
                                </div>
                                <Button name={"Send"} theme={"ovoky-comp-button-small-button"} />
                            </form>
                        </div>
                }
                <img className="icon-form" src="/images/form-icon.png" alt="" />
            </div>
        </div>
    )
}
