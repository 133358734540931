import React, { useState } from 'react'
import "./one-article.css";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
export default function OneArticle({ options }) {
    const [link, setLink] = useState("");
    const handleclick = (e) => {
        const href = e.target.href.split('#')
        var id = href[href.length - 1];
        setLink(id);
    }
    const [save, setSave] = useState(false);
    // const [saved, setSaved] = useState(true);
    const [copie, setCopie] = useState(false);
    const [like, setLike] = useState(false);
    const [share, setShare] = useState(false);
    const onSave = () => {
        setSave(p => !p);
    }
    const onCopie = () => {
        setCopie(true);
        setTimeout(() => {
            setCopie(false)
        }, 1000)

    }
    const onLike = () => {
        setLike(p => !p);
    }
    const onShare = () => {
        setShare(p => !p);
    }


    return (
        <>
            {
                options.map((info, index) => (
                    <div className='one-article' key={index}>
                        <div className='sticky'>
                            <div style={{ top: "80px" }}>
                                <div className={save === true ? "save-img active" : "save-img"} onClick={onSave}><BookmarkIcon /><span>{save === true ? "saved" : "save"}</span></div>
                                <div className='copie-div' onClick={onCopie}><InsertLinkOutlinedIcon /><div className='line'></div><span>{copie === true ? "copied" : "copie"}</span></div>

                            </div>
                            <div style={{ top: "500px" }}>
                                <div className={like === true ? "like-btn active" : "like-btn"} onClick={onLike}><FavoriteBorderOutlinedIcon /><div className='line'></div><span>{like === true ? info.like + 1 : info.like} Likes</span></div>
                                <div className={share === true ? "share-btn active" : "share-btn"} onClick={onShare}><ReplyOutlinedIcon /><div className='line'></div><span>{share === true ? info.share + 1 : info.share}</span></div>
                            </div>
                        </div>
                        <div className='one-article-header'>
                            <div className='one-article-info'>
                                <div className="parag">
                                    <p className='ovoky-text-style-p1-body'>{info.timetoread} minutes to read</p>
                                    <p className='ovoky-text-style-p1-body'>published on {info.datepublished} </p>
                                    <p className='ovoky-text-style-p1-body'>{info.views} views</p>
                                </div>
                                <h1 className='ovoky-text-style-H0-title-hero-2'>{info.title}</h1>
                                <h3 className='ovoky-text-style-title-H3'>{info.subtitle}</h3>
                            </div>
                            <div className='one-article-foot'>
                                <div>
                                    <img src="/images/author7.png" alt="" />
                                    <p className='ovoky-text-style-p1-body'>{info.author}</p>
                                </div>
                            </div>
                        </div>
                        <div className="one-article-body">
                            <div className='nav-bar-vertical'>
                                <div>
                                    <div className={link === "intro" ? "active" : null}><span style={{ top: "-8px" }}>Intro</span><a className={link === "intro" ? "active" : null} href='#intro' onClick={(e) => handleclick(e)}> </a></div>
                                    <div className={link === "tips" ? "active" : null}><span style={{ top: "56px" }}>25%</span><a className={link === "tips" ? "active" : null} href='#tips' onClick={(e) => handleclick(e)}> </a></div>
                                    <div className={link === "capturing" ? "active" : null}><span style={{ top: "120px" }}>50%</span><a className={link === "capturing" ? "active" : null} href='#capturing' onClick={(e) => handleclick(e)}> </a></div>
                                    <div className={link === "conclusion" ? "active" : null}><span style={{ top: "185px" }}>75%</span><a className={link === "conclusion" ? "active" : null} href='#conclusion' onClick={(e) => handleclick(e)}> </a></div>
                                    <div className={link === "whats-next" ? "active" : null}><span style={{ top: "249px", left: "-70px" }}>Done !</span><a className={link === "whats-next" ? "active" : null} href='#whats-next' onClick={(e) => handleclick(e)}> </a></div>
                                </div>
                            </div>
                            <div className='article-body-container'>
                                <div id='intro' className='intro'>
                                    <h3 className='ovoky-text-style-title-H3'>Introduction</h3>
                                    <p className='ovoky-text-style-p1-body'>{info.intro}</p>
                                </div>
                                <div className='descp-article'>
                                    <h3 className='ovoky-text-style-title-H3'>The Magic of Underwater Photography:</h3>
                                    <p className='ovoky-text-style-p1-body'>{info.underwaterPhotography}</p>
                                </div>
                                <div id='tips' className='tips'>
                                    <div>
                                        <h3 className='ovoky-text-style-title-H3'>Tips for Capturing Compelling Images:</h3>
                                        <ul>
                                            {
                                                info.tips.map((t, i) => (
                                                    <div key={i}>
                                                        <li>{t.tip}</li>
                                                    </div>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className='images'>
                                        <div className="fist-bg"><img src={info.imgSrc} alt="" /></div>
                                        <div className="second-bg"><img src={info.imgSrc} alt="" /></div>
                                        <div className="third-bg"><img src={info.imgSrc} alt="" /></div>
                                    </div>
                                </div>
                                <div id='capturing'>
                                    <h3 className='ovoky-text-style-title-H3'>Capturing Underwater Wildlife:</h3>
                                    <p className='ovoky-text-style-p1-body'>{info.capturing}</p>
                                </div>
                                <div className='capturing'>
                                    <img src="/images/capturing.png" alt="" />
                                    <div>
                                        <h3 className='ovoky-text-style-title-H3'>Capturing Underwater Wildlife:</h3>
                                        <p className='ovoky-text-style-p1-body'>{info.capturing}</p>
                                    </div>
                                </div>
                                <div id='conclusion'>
                                    <h3 className='ovoky-text-style-title-H3'>Conclusion</h3>
                                    <p className='ovoky-text-style-p1-body'>{info.conclusion}</p>
                                </div>
                                <div id='whats-next' className='whats-next'>
                                    <h3 className='ovoky-text-style-title-H3'>What's Next?</h3>
                                    <ul>
                                        {
                                            info.whatsNext.map((n, i) => (
                                                <li key={i}>{n.text}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }

        </>
    )
}
