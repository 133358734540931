import React, { useState, useEffect } from 'react';
import "./review.css";

export default function Review ({ reviews, numberOfReviewsToShow }) {
    const [currentReviewIndices, setCurrentReviewIndices] = useState(
        Array.from({ length: numberOfReviewsToShow }, (_, index) => index)
    );

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentReviewIndices(prevIndices =>
                prevIndices.map((index, i) =>
                    index === reviews.length - 1 ? 0 : index + 1
                )
            );
        }, 4000);

        return () => clearInterval(intervalId);
    }, [reviews.length, numberOfReviewsToShow]);

    return (
        <div className="reviews-loop">
            {currentReviewIndices.map((index, i) => (
                <div className="review" key={i}>
                    <div className='person-info'>
                        <img src={reviews[index].srcImage} alt='' />
                        <div>
                            <p>{reviews[index].name} </p>
                            <img src={reviews[index].stars} alt='' />
                        </div>
                    </div>
                    <div className='comment'><p>{reviews[index].comment} </p></div>
                </div>
            ))}
        </div>
    );
};
