import Card from "./Card";
import "./main-product.css";
import React, { useState } from "react";
import productInfos from '../data/productsInfo.json'

export default function MainProduct({ titles }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const handleClick = (index) => {
        setCurrentIndex(index)
    }
    return (
        <div className="main-product">
            <div className='card-buttons'>
                {
                    titles.map((title, index) => (
                       
                            <button key={index} data-index={index} className={index === currentIndex ? `ovoky-text-style-title-H3 ovoky-comp-button-bg-index-${currentIndex} active` : `ovoky-text-style-title-H3 ovoky-comp-button-bg-index-${index}`} onClick={() => handleClick(index)}> {title.name} </button>
                       
                    ))
                }
            </div> 
            <div className="card-infos">
                <Card options={productInfos} currentIndex={currentIndex} />
            </div>

        </div>
    )
}
