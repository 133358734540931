import "./pop-up.css";
import { Children } from 'react';
import { ReactComponent as LeftArrowIcon } from "../assets/left.svg"

export default function PopUp({ open, children, popUpRef, closePopUp }) {
    return (
        <>
            {
                open &&
                <div className='modal' ref={popUpRef}>
                    <div className='image'>
                        <img src="/images/popUp.png" alt="popUp" />
                    </div>
                    <div className='text'>
                        <>
                            {Children.map(children, child => <>{child}</>)}
                        </>
                        {/* <div className="break">
                            <span></span>
                            <div>OR</div>
                            <span></span>
                        </div>
                        <ul>
                            <li><img src="/images/email.png" alt="email icon" /></li>
                            <li><img src="/images/facebook.png" alt="facebook icon" /></li>
                        </ul>
                        <div className='button-account'><button onClick={handleOpen}>{buttonName}</button></div> */}
                    </div>
                </div>
            }
            <div className={open === true ? "modal-phone open" : "modal-phone"}>
                <button className="back-button" onClick={closePopUp}><LeftArrowIcon /></button>
                <div className='text'>
                    <>
                        {Children.map(children, child => <>{child}</>)}
                    </>
                    {/* <div className="break">
                        <span></span>
                        <div>OR</div>
                        <span></span>
                    </div>
                    <ul>
                        <li><img src="/images/email.png" alt="email icon" /></li>
                        <li><img src="/images/facebook.png" alt="facebook icon" /></li>
                    </ul>
                    <div className='button-account'><button onClick={handleOpen}>{buttonName}</button></div> */}
                </div>
            </div>
        </>
    )
}
