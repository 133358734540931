import React from 'react'
import OneArticle from '../Components/OneArticle';
import articleInfo from "../data/articleInfo.json";
import Subscribe from './Subscribe';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FrequentlyAQ from './FrequentlyAQ';
import frequentlyAQ from "../data/frequentlyAQ";
import articles from "../data/articles";
import Article from '../Components/Article';
import ArticlesList from './ArticlesList';
import Tags from '../Components/Tags';
export default function OneArticlePage() {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    let articleInfos = [];
    let tags = [];

    if (articleInfo.map(info => info.idArticle).includes(parseInt(id)) === true) {
        articleInfos = articleInfo.filter(info => info.idArticle === parseInt(id));
        tags = articleInfo.find(a => a.idArticle === parseInt(id));
    }
    return (
        <>{
            articleInfos.length > 0 ?
                <>
                    <OneArticle options={articleInfos} />
                    <ArticlesList title={"Similar articles:"} titleColor={"var(--light-blue)"} fontTitle={"28px"}>
                        <div className="container">
                            <Article options={articles} limit={3} />
                        </div>
                    </ArticlesList>
                    <a className="article-link" href="/blog/articles">Load More <KeyboardArrowDownIcon /></a>
                    <Tags title={"Tags related to the Article"} tags={tags.tags} />
                    <Subscribe />
                    <FrequentlyAQ fastAQ={frequentlyAQ} />
                </> :
                <div className='not-found'>
                    <h1>Not found </h1>
                </div>
        }
        </>
    )
}
