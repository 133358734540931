import "./news.css";
import CardBlog from "./CardBlog";
import blogs from '../data/blogs.json'
import CircularMenu from "./CircularMenu";
export default function News({ setShowSearchBar}) {
  return (
    <div className='news'>
      <div className="news-blog">
        {
          blogs.map((option, i) => (
            <a href={`/blog/article?id=${i+1}`} key={i}>
              <CardBlog option={option} />
            </a>
          ))
        }
      </div>
      <CircularMenu setShowSearchBar={setShowSearchBar} />
    </div>
  )
}
